<template>
    <!-- 导航 -->
    <page-head title="训练计划" />
    <content-bg>
        <template v-slot:content>
            <div v-if="!loading" class="mian">
                <!-- 头部 -->
                <div class="head">
                    <div class="avatar_box">
<!--                        <img :src="$utils.defaultAvatar(headCoachData.avatar)" />-->
<!--                        <p>{{ headCoachData.title }}  {{ headCoachData.name }}</p>-->
                    </div>
                    <div class="total">
                        <div class="row">第{{ nowWeek }}周</div>
                        <div class="row">
                            今日工作
                            <span class="max akrobatBold">{{ todaySchedule.length }}</span>
                            <span class="min">项</span>
                        </div>
                        <div class="row">
                            本周训练时长<span class="max akrobatBold">{{ trainingDuration }}</span><span class="min">分钟</span>
                        </div>
                    </div>
                </div>
                <div class="view_week">
                    <!-- 顶部周一至周日 -->
                    <div class="task_box">
                        <div v-for="(item, key) in scheduleData" :key="key + 'date'" class="week_date" :style="key === 6 ? 'border-right: none;' : ''">
                            <!-- 日期 -->
                            <p class="date akrobatRegular" :class="{'activeDate': isToday(item.date)}">
                                {{ $moment(item.date).format('MM/DD') }}&nbsp;&nbsp;{{ weekInd[key] }}
                            </p>
                            <!-- 标题 -->
                            <div class="task">
                                <!-- 有标题 -->
                                <div v-if="item.task && item.task.id" :class="{'active': isToday(item.date)}">
                                    <p class="title">{{ item.task.title }}</p>
                                    <p class="rpe">RPE {{ item.task.rpe }}</p>
                                </div>
                            </div>
                            <!-- 内容 -->
                            <div v-if="item.workitem.length > 0" class="schedule_content">
                                <div class="schedule_work">
                                    <div
                                        v-for="el in item.workitem"
                                        :key="el.id"
                                        @click="jumpTo(item, el)"
                                        class="work"
                                        :class="`work_${el.itemType === 'train'? 'training' : el.itemType}`"
                                    >
                                        <img class="icon" :src="require(`@i/schedule/work_icon_${el.itemType === 'train'? 'training' : el.itemType}.svg`)" />
                                        <div class="akrobatRegular">
                                            {{el.start}} - {{el.end}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- 日程主题 -->
                            <div v-if="item.task && item.task.theme" class="theme">
                                <img v-if="isToday(item.date)" :src="require(`@i/schedule/tu_schedule_${item.task.theme}_active.svg`)" />
                                <img v-else :src="require(`@i/schedule/tu_schedule_${item.task.theme}.svg`)" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </content-bg>
</template>

<script>
import { useRouter, useRoute } from "vue-router";
import { getCurrentInstance, reactive, toRefs, onMounted } from "vue";
import { useStore } from "vuex";

export default {
    setup() {
        const route = useRoute();
        const router = useRouter();
        const store = useStore();
        const { proxy } = getCurrentInstance()
        const state = reactive({
            loading: true,
            teamName: '',
            nowWeek: '', // 当前周
            today: proxy.$moment(new Date()).format('YYYY/MM/DD'), // 今天
            headCoachData: {}, // 教练数据
            trainingDuration: 0, // 时长
            todaySchedule: [], // 今日日程
            scheduleData: {}, // 日程
            weekInd: ['周一', '周二', '周三', '周四', '周五', '周六', '周日'],
        });

        const getNowWeekData = async () => {
            const { code, data } = await proxy.$server.nowWeek();
            if (code === 200) {
                state.nowWeek = data;
                getData();
            }
        };

        const getData = async () => {
            const { id } = store.state.teamData;
            state.loading = true
            let params = {
                // teamId: id, // 球队id
                weeks: getWeekDay().join(','),
            }
            if (route.query.type === 'team') {
                params['teamId'] = id
            }else if (route.query.type === 'camp') {
                params['campId'] = id
            }

            const { code, data } = await (route.query.type === 'team' ? proxy.$server.weekSchedule(params) : proxy.$server.getCampWorkItem(params))

            // console.log(code, data)
            if (code === 200) {
                data.schedule.forEach(item => {
                    item.workitem = item.workitem.filter(ele => {
                        if(ele.itemType === 'training' || ele.itemType === 'match' || ele.itemType === 'train') return ele
                    })
                })
                state.headCoachData = data.headCoach
                state.scheduleData = data.schedule || [];
                let obj = {}
                data.schedule.map(item => {
                    obj[item.date] = item
                })
                state.todaySchedule = obj[state.today] ? obj[state.today].workitem : [];
                state.loading = false
            }
        };

        const getWeekDay = () => {
            let presentDate = new Date(state.today);
            let today = presentDate.getDay() !== 0 ? presentDate.getDay() : 7;

            return Array.from(new Array(7), (val, index) => {
                let date = new Date(presentDate.getTime() - (today - index - 1) * 24 * 60 * 60 * 1000)
                return proxy.$moment(date).format('YYYY/MM/DD')
            });
        }

        // 是否是今天
        const isToday = (day) => {
            const date = proxy.$moment().format('YYYY/MM/DD');
            return date === day;
        }
        
        const jumpTo = (row, ele) => {
            const params = {
                workItem: row.workitem,
                activeItem: ele
            }
            store.commit('setPlanData', params)
            router.push({
                path: '/trainingPlan/details'
            })
        }
        onMounted(() => {
            getNowWeekData();
        });

        return {
            isToday,
            jumpTo,
            ...toRefs(state),
        }
    }
}
</script>

<style scoped lang="scss">
.mian {
    padding: 40px 35px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    .head {
        display: flex;
        justify-content: space-between;
        color: #fff;
        .avatar_box {
            display: flex;
            align-items: center;
            img {
                margin-right: 16px;
                width: 64px;
                height: 64px;
                border-radius: 50%;
            }
            p {
                font-size: 25px;
            }
        }
        .total {
            display: flex;
            align-items: flex-end;
            .row {
                margin-left: 24px;
                font-size: 25px;
                display: flex;
                align-items: flex-end;
                .max {
                    padding: 0 8px;
                    font-weight: 700;
                    font-size: 50px;
                    color: #60BF52;
                    line-height: 40px;
                }
                .min {
                    font-size: 16px;
                    opacity: 0.8;
                }
            }
        }
    }
    .view_week {
        margin-top: 32px;
        width: 100%;
        box-sizing: border-box;

        .task_box {
            height: 609px;
            display: flex;
            border: 1px solid rgba(255, 255, 255, 0.15);

            .week_date {
                position: relative;
                flex: 1;
                border-right: 1px solid rgba(255, 255, 255, 0.15);
                color: rgba(255, 255, 255, 0.7);
                .date {
                    height: 46px;
                    text-align: center;
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 46px;
                    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
                }
                .activeDate {
                    color: #24E1A6;
                }
                .task {
                    padding: 12px 16px;
                    position: relative;
                    height: 80px;
                    overflow: auto;
                    .title {
                        margin-bottom: 12px;
                        font-size: 16px;
                    }
                    .rpe {
                        font-size: 14px;
                    }
                }
                .active {
                    color: #fff;
                }
                .schedule_content {
                    display: flex;
                    align-items: flex-end;
                    padding: 0 8px;
                    margin-top: 44px;
                    height: 410px;
                    box-sizing: border-box;
                    padding-bottom: 100px;
                    overflow: auto;
                    .schedule_work {
                        .work {
                            position: relative;
                            display: flex;
                            align-items: center;
                            padding: 0 4px;
                            width: 167px;
                            height: 48px;
                            border-radius: 8px;
                            margin-bottom: 8px;
                            font-size: 20px;
                            cursor: pointer;
                            box-sizing: border-box;

                            &.work_match {
                                background: url('~@i/schedule/work_match.png') no-repeat;
                                background-size: 100% 100%;
                            }

                            &.work_training {
                                background: url('~@i/schedule/work_training.png') no-repeat;
                                background-size: 100% 100%;
                            }
                            &.work_train {
                                background: url('~@i/schedule/work_training.png') no-repeat;
                                background-size: 100% 100%;
                            }

                            .icon {
                                margin-right: 24px;
                                width: 46px;
                                height: 46px;
                            }

                            // &.work_recovery {
                            //     background: url('../../../assets/images/schedule/work_recovery.png') no-repeat;
                            //     background-size: 100% 100%;

                            //     &::after {
                            //         background: #00cd8d;
                            //     }
                            // }

                            // &.work_meeting {
                            //     background: url('../../../assets/images/schedule/work_meeting.png') no-repeat;
                            //     background-size: 100% 100%;

                            //     &::after {
                            //         background: #587de3;
                            //     }
                            // }

                            // &.work_travel {
                            //     background: url('../../../assets/images/schedule/work_travel.png') no-repeat;
                            //     background-size: 100% 100%;

                            //     &::after {
                            //         background: #34b5e8;
                            //     }
                            // }

                            // &.work_study {
                            //     background: url('../../../assets/images/schedule/work_study.png') no-repeat;
                            //     background-size: 100% 100%;

                            //     &::after {
                            //         background: #f0853e;
                            //     }
                            // }

                            // &.work_watch {
                            //     background: url('../../../assets/images/schedule/work_watch.png') no-repeat;
                            //     background-size: 100% 100%;

                            //     &::after {
                            //         background: #525cac;
                            //     }
                            // }
                        }
                    }
                }
                .theme {
                    position: absolute;
                    bottom: 10px;
                    left: 50%;
                    transform: translateX(-50%);
                    img {
                        width: 60px;
                        height: 60px;
                    }
                }
            }
        }
    }
}
</style>